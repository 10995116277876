import React from "react";
import {
  DataTable,
  ModalConfirmation,
  ModalConfirmationV2,
  Pagination,
  TableMenu,
} from "@bluesilodev/timhutcomponents";
import { useSelector } from "react-redux";

const LeaveTypeDataTable = ({ dataTable, totalData, rowsPerPage, currentPage, onChangeCurrentPage, onChangeRowsPerPage, onEditClick, onDeleteClick }) => {
  // Redux State
  const { currentRole } = useSelector((state) => state.userData);
  const cell = (status, leaveTypeId) => {
    return (
      <div className="hover:cursor-pointer w-full">
        <h1>{status}</h1>
      </div>
    );
  };
  return (
    <React.Fragment>
      <DataTable
        columns={[
          {
            id: "leaveType",
            accessorFn: (row) => row,
            enableSorting: true,
            header: () => <span>Leave Type</span>,
            cell: (status) => {
              return cell(
                `${status.getValue().name}`,
                status.getValue().leaveTypeId,
              );
            },
          },
          {
            id: "defaultAmount",
            accessorFn: (row) => row,
            enableSorting: true,
            header: () => <span>Default Amount</span>,
            cell: (status) => {
              return cell(
                `${status.getValue().defaultAmount} Day`,
                status.getValue().leaveTypeId,
              );
            },
          },
          {
            id: "gainPerMonth",
            accessorFn: (row) => row,
            enableSorting: true,
            header: () => <span>Gain Per Month</span>,
            cell: (status) => {
              return cell(
                `${status.getValue().gainPerMonth} Day`,
                status.getValue().leaveTypeId,
              );
            },
          },
          {
            id: "expiredCarryOver",
            accessorFn: (row) => row,
            enableSorting: true,
            header: () => <span>Expired Carry Over</span>,
            cell: (status) => {
              return cell(
                `${status.getValue().expiredCarryOver} Month`,
                status.getValue().leaveTypeId,
              );
            },
          },
          {
            id: "maxCarryOver",
            accessorFn: (row) => row,
            enableSorting: true,
            header: () => <span>Max Carry Over</span>,
            cell: (status) => {
              return cell(
                `${status.getValue().maxCarryOver} Day`,
                status.getValue().leaveTypeId,
              );
            },
          },
          {
            accessorFn: (row) => row,
            disableSorting: true,
            header: () => {},
            id: "action",
            noPadding: true,
            cell: (status) => {
              return (
                <div className="cursor-pointer w-fit h-[20px]">
                  {["Admin", "Supervisor"].includes(currentRole) && (
                    <TableMenuHandler
                      show={["Admin", "Supervisor"].includes(currentRole)}
                      onEditClick={() => {
                        const findSingleLeaveType = dataTable.find(
                          (val) => val.leaveTypeId === status.getValue().leaveTypeId,
                        );
                        onEditClick(findSingleLeaveType);
                      }}
                      onDeleteClick={() => {
                        const findSingleLeaveType = dataTable.find(
                          (val) => val.leaveTypeId === status.getValue().leaveTypeId,
                        );
                        onDeleteClick(findSingleLeaveType);
                      }}
                    />
                  )}
                </div>
              );
            },
          },
        ]}
        data={dataTable}
        pagination={false}
      />
      <div className="py-2" />
      <Pagination
        totalData={totalData}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onChangeCurrentPage={onChangeCurrentPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </React.Fragment>
  );
};

export default LeaveTypeDataTable;

function TableMenuHandler({ show, onEditClick, onDeleteClick }) {
  const [showModal, setShowModal] = React.useState(false);
  const confirmModal = () => {
    setShowModal(false);
    onDeleteClick();
  };
  const cancelModal = () => {
    setShowModal(false);
  };
  return (
    <React.Fragment>
      <TableMenu
        show={show}
        onEdit={onEditClick}
        onDelete={() => setShowModal(true)}
      />
      {showModal && (
        // <ModalConfirmation
        //   headMessage="Are you sure want to delete it?"
        //   onClose={cancelModal}
        //   onConfirm={confirmModal}
        // />
        <ModalConfirmationV2
        className={"min-w-[600px]"}
        title={"Delete Leave"}
        headMessage={""}
        message={
          <div className="text-left w-full text-lg font-light">
            Do you want to delete this item?
          </div>
        }
        // onClose={() => setIsShow(false)}
        onClose={cancelModal}
        // onConfirm={() => {
        //   requestPayroll({ id: original?._id });
        //   dispatch(PayrollApi.util.invalidateTags(["payroll"]));
        //   setIsShow(false);
        // }}
        onConfirm={confirmModal}
      />
      )}
    </React.Fragment>
  )
}
