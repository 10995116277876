import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import userSlice from "store/slices/user";
import { commonApi } from "./apis/commonApi";
import { externalApi } from "./apis/externalApi";
import { leaveBalanceApi } from "./apis/leaveBalanceApi";
import { leaveReportApi } from "./apis/leaveReportApi";
import { leaveSettingsApi } from "./apis/leaveSettingsApi";
import { leaveTypeApi } from "./apis/leaveTypeApi";
import { leaveUserApi } from "./apis/leaveUserApi";

const persistConfig = {
  key: "root",
  storage,
  // blacklist: ["userData"],
};

const rootReducer = combineReducers({
	userData: userSlice,
  [commonApi.reducerPath]: commonApi.reducer,
  [externalApi.reducerPath]: externalApi.reducer,
  [leaveBalanceApi.reducerPath]: leaveBalanceApi.reducer,
  [leaveReportApi.reducerPath]: leaveReportApi.reducer,
  [leaveSettingsApi.reducerPath]: leaveSettingsApi.reducer,
  [leaveTypeApi.reducerPath]: leaveTypeApi.reducer,
  [leaveUserApi.reducerPath]: leaveUserApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(thunk)
      .concat(commonApi.middleware)
      .concat(externalApi.middleware)
      .concat(leaveBalanceApi.middleware)
      .concat(leaveReportApi.middleware)
      .concat(leaveSettingsApi.middleware)
      .concat(leaveUserApi.middleware)
      .concat(leaveTypeApi.middleware);
  },
});

setupListeners(store.dispatch);

export default configureStore({
  reducer: {},
});

export {
  useFetchAppsQuery,
} from "./apis/commonApi";
export {
  useFetchLocationDepartmentQuery,
  useFetchShiftByUserIDQuery,
  useFetchShiftByUserIDAndDateQuery,
} from "./apis/externalApi";
export {
  useFetchLeaveBalanceQuery,
  useFetchLeaveBalanceUserQuery,
  useFetchLeaveExpiredQuery,
  useAddLeaveBalanceMutation,
  useUpdateLeaveBalanceMutation,
} from "./apis/leaveBalanceApi";
export {
  useFetchLeaveQuery,
  useFetchLeaveExcessiveUserQuery,
  useFetchLeaveUserQuery,
  useAddLeaveMutation,
  useUpdateLeaveMutation,
  useDeleteLeaveMutation,
} from "./apis/leaveReportApi";
export {
  useFetchLeaveSettingsQuery,
  useAddLeaveSettingsMutation,
} from "./apis/leaveSettingsApi";
export {
  useFetchLeaveTypeQuery,
  useAddLeaveTypeMutation,
  useUpdateLeaveTypeMutation,
  useDeleteLeaveTypeMutation,
} from "./apis/leaveTypeApi";
export {
  useFetchLeaveUserTypeByTokenQuery,
} from "./apis/leaveUserApi"

export const persistor = persistStore(store);
