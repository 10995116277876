import React, { useState } from "react";
import {
  Accordion,
  InputSelect,
  InputText,
} from "@bluesilodev/timhutcomponents";
import { Outlet, useParams } from "react-router-dom";
import FetchingAndError from "components/fetchingAndError";
import { LeaveReportsExcessiveDetailDataTable } from "pages/leaveReports/tables";
import { useFetchShiftByUserIDQuery } from "store/apis/externalApi";
import { useFetchLeaveExcessiveUserQuery } from "store/apis/leaveReportApi";

const dataTableDummy = [];
/*
const dataTableDummy = [
  {
    employee: "Halim",
    uId: "298d",
    jobPosition: {
      position: "Supervisor",
      departement: "Operation",
    },
    shift: "Office Working Hour",
    startTime: {
      time: "12:00",
      date: "24 August 2024",
    },
    endTime: {
      time: "15:00",
      date: "24 August 2024",
    },
    duration: "Half Shift(3 Hours)",
    leaveType: "Annual Leave",
    status: "Pending",
  },

  {
    employee: "Henry",
    uId: "298d",
    jobPosition: {
      position: "Cook",
      departement: "Operation",
    },
    shift: "Office Working Hour",
    startTime: {
      time: "12:00",
      date: "24 August 2024",
    },
    endTime: {
      time: "15:00",
      date: "24 August 2024",
    },
    duration: "Half Shift(3 Hours)",
    leaveType: "Annual Leave",
    status: "Pending",
  },

  {
    employee: "Howard",
    uId: "212355",
    jobPosition: {
      position: "Supervisor",
      departement: "Operation",
    },
    shift: "Office Working Hour",
    startTime: {
      time: "12:00",
      date: "24 August 2024",
    },
    endTime: {
      time: "15:00",
      date: "24 August 2024",
    },
    duration: "Half Shift(3 Hours)",
    leaveType: "Annual Leave",
    status: "Pending",
  },
];
*/

const ExcessiveLeave = () => {
  let { userID } = useParams();
  // Data State
  const [dataTable, setDataTable] = useState({ userInformation: {}, excessiveData: dataTableDummy });
  const [paginate, setPaginate] = useState({ totalData: 0, rowsPerPage: 10, currentPage: 1 });
  // Redux Toolkit
  const { data: dataShift, error: errorShift, isFetching: isFetchingShift } = useFetchShiftByUserIDQuery({ fetchShiftByUserID: userID || "" }, { refetchOnMountOrArgChange: true });
  const { data: dataLeaveExcessive, error: errorLeaveExcessive, isFetching: isFetchingLeaveExcessive } = useFetchLeaveExcessiveUserQuery({ id: userID, limit: paginate.rowsPerPage, page: paginate.currentPage }, { refetchOnMountOrArgChange: true });

  // Static State
  /*
  const shift = ["Day", "Night"];
  */

  React.useEffect(() => {
    if (dataShift && dataLeaveExcessive) {
      const switchCaseApproval = (status) => {
        switch (status) {
          case "approved": return "Approved";
          case "rejected": return "Rejected";
          case "pending": return "Pending";
          default: return "Pending";
        }
      };
      setPaginate((oldData) => ({ ...oldData, totalData: dataLeaveExcessive.totalDocs }));
      const employeeInformation = { ...dataLeaveExcessive.data.userInformation, shift: dataShift?.assignShifts?.[0]?.shift?.shiftName || "???" };
      const excessiveLeave = dataLeaveExcessive.data.excessiveData.map((val) => {
        const leaveTimes = [...val.leaveTimes].sort((a, b) => a.date > b.date);
        return {
          employee: employeeInformation?.userName || "",
          employeeID: employeeInformation?.employeeID || "",
          userID: userID,
          location: employeeInformation?.userInformation?.employeementDetail?.locations || "",
          uId: val.uId,
          photo: employeeInformation?.photo?.[0]?.link || "",
          jobPosition: {
            jobPosition: employeeInformation?.userInformation?.employeementDetail?.jobPosition || "",
            department: employeeInformation?.userInformation?.employeementDetail?.departments || "",
          },
          shift: employeeInformation.shift,
          startTime: {
            time: leaveTimes[0].time || "00:00",
            date: leaveTimes[0].date,
          },
          endTime: {
            time: leaveTimes[leaveTimes.length - 1].time || "00:00",
            date: leaveTimes[leaveTimes.length - 1].date,
          },
          duration: val.totalDuration,
          leaveType: val.leaveType.name,
          approvalStatus: {
            bySupervisor: {
              status: switchCaseApproval(val.approvalStatus.bySupervisor.status),
            },
            byHr: {
              status: switchCaseApproval(val.approvalStatus.byHr.status),
            },
          },
          status: val.approvalStatus.byHr.status === "pending" || val.approvalStatus.bySupervisor.status === "pending" ? "Pending" : val.approvalStatus.byHr.status === "approved" ? "Approved" : "Rejected",
        };
      });
      setDataTable({
        userInformation: employeeInformation,
        excessiveData: excessiveLeave,
      });
    }
  }, [dataShift, dataLeaveExcessive]);

  const employeeDetail = (
    <>
      <div className="w-full flex flex-row">
        <div className="self-center border border-[#A3A3AB] rounded-lg p-2">
          <img className="object-cover rounded-[5px] w-[150px] h-[150px]" src={dataTable.userInformation?.photo?.[0]?.link} alt="User Profile" />
        </div>

        <div className="flex flex-col p-1 ml-10 gap-2 w-full">
          <div className="grid grid-cols-3 gap-5">
            <InputText
              title={"First Name"}
              label={<React.Fragment></React.Fragment>}
              options={[{ label: dataTable.userInformation?.firstName, value: dataTable.userInformation?.firstName }]}
              value={dataTable.userInformation?.firstName}
              disabled={true}
            />
            <InputText
              title={"Last Name"}
              label={<React.Fragment></React.Fragment>}
              options={[{ label: dataTable.userInformation?.lastName, value: dataTable.userInformation?.lastName }]}
              value={dataTable.userInformation?.lastName}
              disabled={true}
            />
            <InputText
              title={"Empoloyee ID"}
              label={<React.Fragment></React.Fragment>}
              options={[{ label: dataTable.employeeID, value: dataTable.employeeID }]}
              value={dataTable.employeeID}
              disabled={true}
            />
            <InputSelect
              title={"Shift"}
              options={[{ label: dataTable.userInformation?.shift, value: dataTable.userInformation?.shift }]}
              value={dataTable.userInformation?.shift}
              disabled={true}
            />
            <InputSelect
              title={"Departments"}
              options={[{ label: dataTable.userInformation?.userInformation?.employeementDetail?.departments, value: dataTable.userInformation?.userInformation?.employeementDetail?.departments }]}
              value={dataTable.userInformation?.userInformation?.employeementDetail?.departments}
              disabled={true}
            />
            <InputSelect
              title={"Job Positions"}
              options={[{ label: dataTable.userInformation?.userInformation?.employeementDetail?.jobPosition, value: dataTable.userInformation?.userInformation?.employeementDetail?.jobPosition }]}
              value={dataTable.userInformation?.userInformation?.employeementDetail?.jobPosition}
              disabled={true}
            />
          </div>
        </div>
      </div>
    </>
  );

  const leaveDetail = (
    <>
      <div className="flex flex-col gap-2">
        <LeaveReportsExcessiveDetailDataTable
          dataTable={dataTable}
          currentPage={paginate.currentPage}
          totalData={paginate.totalData}
          rowsPerPage={paginate.rowsPerPage}
          onChangeCurrentPage={(val) => setPaginate((oldData) => ({ ...oldData, currentPage: val + 1 }))}
          onChangeRowsPerPage={(val) => setPaginate((oldData) => ({ ...oldData, rowsPerPage: val }))}
        />
      </div>
    </>
  );

  return (
    <>
      <div className="w-full h-max relative mt-5 p-1">
        <FetchingAndError isFetching={isFetchingLeaveExcessive} isError={errorLeaveExcessive}>
          <div className="w-full mt-5">
            <Accordion title={<div>Employee Details</div>} icons={[]} children={employeeDetail} />
          </div>

          <div className="w-full mt-5">
            <Accordion title={<div>Leave Details</div>} icons={[]} children={leaveDetail} />
          </div>
        </FetchingAndError>
      </div>
      <Outlet />
    </>
  );
};

export default ExcessiveLeave;
