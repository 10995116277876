import React from "react";
import { alertSuccess, alertError } from "utils/alert";

export const useAlertApi = (queryApiMutationResults, onSuccess, onError) => {
  React.useEffect(() => {
    queryApiMutationResults.isSuccess && executeAlertSuccess("Success", queryApiMutationResults.data?.message);
    queryApiMutationResults.isError && executeAlertError("Error", queryApiMutationResults.error?.data?.message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryApiMutationResults]);

  const executeAlertSuccess = (title, message) => {
    alertSuccess(title || "Success", message || "Success");
    onSuccess?.();
  };

  const executeAlertError = (title, message) => {
    alertError(title || "Success", message || "Error");
    onError?.();
  };
};
