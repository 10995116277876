import React, { useState } from "react";
import {
  Accordion,
  Button,
  InputDate,
  InputSelect,
  InputText,
  TextArea,
  ModalDialog,
} from "@bluesilodev/timhutcomponents";
import { useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import CircleXMarxSVG from "assets/icon/CircleXMarkSVG/CircleXMarkSVG";
import DownloadSVG from "assets/icon/DownloadSVG/DownloadSVG";
import FileTypeJPGSVG from "assets/icon/FileTypeJPGSVG/FileTypeJPGSVG";
import UserAddIcon from "assets/icon/UserAddSVG/UserAdd";
import XMarkSVG from "assets/icon/XMarkSVG/XMarkSVG";
import FetchingAndError from "components/fetchingAndError";
import { LeaveReportsDetailDataTable } from "pages/leaveReports/tables";
import { useAlertApi } from "services/alert-api";
import { useFetchShiftByUserIDQuery } from "store/apis/externalApi";
import { useFetchLeaveBalanceUserQuery } from "store/apis/leaveBalanceApi";
import { useFetchLeaveUserQuery, useUpdateLeaveMutation } from "store/apis/leaveReportApi";

const LeaveDetails = () => {
  let { userID, leaveID } = useParams();
  // Redux State
  const { currentRole } = useSelector((state) => state.userData);
  // Data State
  const [leaveReportsDetailsEmployee, setLeaveReportsDetailsEmployee] = useState({});
  const [leaveReportsDetailsData, setLeaveReportsDetailsData] = useState({});
  const [leaveReportsDetailsBalance, setLeaveReportsDetailsBalance] = useState([]);
  const [rejectReason, setRejectReason] = useState("");
  // Element State
  const [isEnter, setEnter] = useState("#DD7224");
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Redux Toolkit
  const { data: dataShift, error: errorShift, isFetching: isFetchingShift } = useFetchShiftByUserIDQuery({ fetchShiftByUserID: userID || "" }, { refetchOnMountOrArgChange: true });
  const { data: dataLeave, error: errorLeave, isFetching: isFetchingLeave } = useFetchLeaveUserQuery({ id: leaveID }, { refetchOnMountOrArgChange: true });
  const { data: dataLeaveBalance, error: errorLeaveBalance, isFetching: isFetchingLeaveBalance } = useFetchLeaveBalanceUserQuery({ id: userID }, { refetchOnMountOrArgChange: true });
  const [approveRejectLeaveReports, approveRejectLeaveReportResult] = useUpdateLeaveMutation();

  // Static State
  /*
  const shift = ["Day", "Night"];
  const dataTable = [
    {
      employee: "Halim",
      leaveType: "Sick Leave",
      availableBalance: "12 Days",
      deductFromLeave: "2.5 Days",
      remainingBalance: "9.5 Days",
    },
  ];
  */

  React.useEffect(() => {
    if (dataShift && dataLeave && dataLeaveBalance) {
      const { employeeInformation, userID, scheduleID, attachments, leaveTimes, leaveType, leaveReason, totalDuration, approvalStatus, createdAt, actionDisable } = dataLeave.data;
      setLeaveReportsDetailsEmployee({
        photo: employeeInformation.photo?.[0]?.link || "",
        shift: dataShift?.assignShifts.find((val) => val.shift.uId === scheduleID)?.shift?.shiftName || "???",
        firstName: employeeInformation.firstName,
        lastName: employeeInformation.lastName,
        userName: employeeInformation.userName,
        employeeID: employeeInformation.employeeID,
        userID: userID,
        location: employeeInformation.locations?.[0]?.address || "",
        department: employeeInformation?.userInformation?.employeementDetail?.departments || "",
        jobPosition: employeeInformation?.userInformation?.employeementDetail?.jobPosition || "",
        requestDate: createdAt,
      });
      setLeaveReportsDetailsData({
        leaveTimes: leaveTimes,
        totalDuration: totalDuration,
        leaveType: leaveType.name,
        leaveReason: leaveReason,
        attachments: attachments,
        approvalStatus: approvalStatus,
        actionDisable: actionDisable,
      });
      const year = new Date().getFullYear();
      const { balance: { [year]: balanceYear } } = dataLeaveBalance;
      setLeaveReportsDetailsBalance(balanceYear.filter((balance) => balance.consumed.some((balanceLeave) => balanceLeave === leaveID)));
    }
  }, [dataShift, dataLeave, dataLeaveBalance]);

  useAlertApi(approveRejectLeaveReportResult);

  const employeeDetail = (
    <>
      <div className="w-full flex flex-row">
        <div className="self-center border border-[#A3A3AB] rounded-lg p-2">
          <img className="object-cover rounded-[5px] w-[150px] h-[150px]" src={leaveReportsDetailsEmployee.photo} alt="User Profile" />
        </div>

        <div className="flex flex-col p-1 ml-10 gap-2 w-full">
          <div className="grid grid-cols-3 gap-5">
            <div className={"w-full"}>
              <InputSelect
                title={"Shift"}
                options={[{ label: leaveReportsDetailsEmployee.shift, value: leaveReportsDetailsEmployee.shift }]}
                value={leaveReportsDetailsEmployee.shift}
                disabled={true}
              />
            </div>
            <div className="w-full"></div>
            <div className="w-full"></div>
            <InputText
              title={"First Name"}
              label={<React.Fragment></React.Fragment>}
              value={leaveReportsDetailsEmployee.firstName}
              disabled={true}
            />
            <InputText
              title={"Last Name"}
              label={<React.Fragment></React.Fragment>}
              value={leaveReportsDetailsEmployee.lastName}
              disabled={true}
            />
            <InputText
              title={"Empoloyee ID"}
              label={<React.Fragment></React.Fragment>}
              value={leaveReportsDetailsEmployee.employeeID}
              disabled={true}
            />
            <InputSelect
              title={"Location"}
              options={[{ label: leaveReportsDetailsEmployee.location, value: leaveReportsDetailsEmployee.location }]}
              value={leaveReportsDetailsEmployee.location}
              disabled={true}
            />
            <InputSelect
              title={"Departments"}
              options={[{ label: leaveReportsDetailsEmployee.department, value: leaveReportsDetailsEmployee.department }]}
              value={leaveReportsDetailsEmployee.department}
              disabled={true}
            />
            <InputSelect
              title={"Job Positions"}
              options={[{ label: leaveReportsDetailsEmployee.jobPosition, value: leaveReportsDetailsEmployee.jobPosition }]}
              value={leaveReportsDetailsEmployee.jobPosition}
              disabled={true}
            />
          </div>
        </div>
      </div>
    </>
  );

  const leaveDetail = (
    <>
      <div className="flex flex-col gap-2">
        <h1 className="font-semibold">Leave Records</h1>
        <div className="grid grid-cols-4 gap-5">
          {leaveReportsDetailsData.leaveTimes && leaveReportsDetailsData.leaveTimes.map((item) => {
            // const inputDate = new Date(item.date);
            // const date = `0${inputDate.getDate()}`.slice(-2);
            // const month = `0${inputDate.getMonth() + 1}`.slice(-2);
            // const year = inputDate.getFullYear();
            return (
              <React.Fragment>
                <div className="grow w-full h-[60px]">
                  <InputDate
                    label={"Date"}
                    value={`${item.date}`}
                    disabled={true}
                  />
                </div>
                <div className="grow w-full h-[60px]">
                  <InputSelect
                    classname={""}
                    options={[{ label: item.scheduleShiftName, value: item.scheduleShiftName }]}
                    title={"Schedule Shift Name"}
                    value={item.scheduleShiftName}
                    disabled={true}
                  />
                </div>
                <div className="grow w-full h-[60px]">
                  <InputSelect
                    classname={""}
                    options={[{ label: item.duration.durationType, value: item.duration.durationType }]}
                    title={"Duration"}
                    value={item.duration.durationType}
                    disabled={true}
                  />
                </div>
                <div className="grow w-full h-[60px]">
                  {item.duration.durationType === "halfTime" && (
                    <InputSelect
                      classname={""}
                      options={[{ label: item.time, value: item.time }]}
                      title={"Time"}
                      value={item.time}
                      disabled={true}
                    />
                  )}
                </div>
              </React.Fragment>
            );
          })}
          {/*
          <div className="grow w-full h-[60px]">
          </div>
          */}
        </div>
        <hr className="my-3" />
        <h1 className="font-semibold">Leave Balance</h1>
        <FetchingAndError isFetching={isFetchingLeaveBalance} isError={errorLeaveBalance}>
          <LeaveReportsDetailDataTable
            dataTable={leaveReportsDetailsBalance}
            data={{
              photo: leaveReportsDetailsEmployee.photo,
              userName: leaveReportsDetailsEmployee.userName,
              totalLeaveDay: leaveReportsDetailsData.leaveTimes ? leaveReportsDetailsData.leaveTimes.reduce((acc, val) => acc + val.duration.time, 0) / 8 : 0,
            }}
          />
        </FetchingAndError>

        <div className="mt-5 gap-5 flex w-3/4">
          <InputText
            title={"Time Duration"}
            label={<React.Fragment></React.Fragment>}
            disabled={true}
            value={leaveReportsDetailsData.totalDuration}
          />
          <InputSelect
            title={"Type of Leave"}
            options={[{ label: leaveReportsDetailsData.leaveType, value: leaveReportsDetailsData.leaveType }]}
            disabled={true}
            value={leaveReportsDetailsData.leaveType}
          />
        </div>

        <div className="mt-5 w-3/4">
          <TextArea label={"Leave Reasons"} disable={true} value={leaveReportsDetailsData.leaveReason} />
        </div>

        {leaveReportsDetailsData.attachments && leaveReportsDetailsData.attachments.map((item, indexItem) => {
          return (
            <div className="mt-5 w-3/4">
              <div className="border-[1px] border-black bg-[#F2F4F5] rounded-md h-[60px] pl-1 overflow-hidden shadow-lg">
                <div className="p-2">
                  <div className="flex flex-row">
                    <div className="flex flex-row items-center gap-3 grow">
                      <FileTypeJPGSVG />
                      <div className="flex flex-col">
                        <div className="font-bold text-black text-xs whitespace-nowrap mb-2">Attachment {indexItem + 1}</div>
                        <p className="font-bold text-gray-500 text-xs whitespace-nowrap">1.6 MB</p>
                      </div>
                    </div>
                    <div className="flex flex-row items-center gap-3">
                      <div className="" onClick={() => window.open(item, "_blank")}>
                        <DownloadSVG />
                      </div>
                      <div className="">
                        <XMarkSVG />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleModalSubmit = () => {
    handleCloseModal();
    clickRejectLeaveReports();
  };

  const clickApproveLeaveReports = () => {
    approveRejectLeaveReports({
      leaveID: leaveID,
      ...(leaveReportsDetailsData.approvalStatus.bySupervisor.status === "pending" && {
        bySupervisor: {
          status: "approved",
          message: "Ok",
        },
      }),
      ...(leaveReportsDetailsData.approvalStatus.bySupervisor.status === "approved" && leaveReportsDetailsData.approvalStatus.byHr.status === "pending" && {
        byHr: {
          status: "approved",
          message: "Ok",
        },
      }),
    });
  };

  const clickRejectLeaveReports = () => {
    approveRejectLeaveReports({
      leaveID: leaveID,
      ...(leaveReportsDetailsData.approvalStatus.bySupervisor.status === "pending" && {
        bySupervisor: {
          status: "rejected",
          comments: rejectReason,
        },
      }),
      ...(leaveReportsDetailsData.approvalStatus.bySupervisor.status === "approved" && leaveReportsDetailsData.approvalStatus.byHr.status === "pending" && {
        byHr: {
          status: "rejected",
          comments: rejectReason,
        },
      }),
    });
  };

  const approvalStatus = () => {
    if (!leaveReportsDetailsData.approvalStatus) return;
    const supervisorStatus = leaveReportsDetailsData.approvalStatus.bySupervisor.status;
    const hrStatus = leaveReportsDetailsData.approvalStatus.byHr.status;
    if (!leaveReportsDetailsData.actionDisable && ["Admin", "Supervisor"].includes(currentRole) && ((supervisorStatus === "pending" && currentRole === "Supervisor") || (supervisorStatus === "approved" && hrStatus === "pending" && currentRole === "Admin"))) {
      return (
        <div className="flex flex-row gap-2">
          <Button
            label={
              <div className="flex px-4 gap-1">
                <CircleXMarxSVG color={"white"} />
                <div>Reject</div>
              </div>
            }
            style={"solid"}
            onClick={handleOpenModal}
            className={"w-[230px]"}
            backgroundColor={"#DD4848"}
          />
          <Button
            label={
              <div className="flex px-4 gap-1">
                <UserAddIcon color={"white"} />
                <div>
                  <span>Approve</span>
                  {leaveReportsDetailsData.approvalStatus.bySupervisor.status === "pending" && " By Supervisor"}
                  {leaveReportsDetailsData.approvalStatus.bySupervisor.status === "approved" && leaveReportsDetailsData.approvalStatus.byHr.status === "pending" && " By Admin"}
                </div>
              </div>
            }
            style={"solid"}
            className={"w-[230px]"}
            backgroundColor={"#4BD394"}
            onClick={clickApproveLeaveReports}
          />
        </div>
      );
    } else if (supervisorStatus === "rejected" || hrStatus === "rejected") {
      return (
        <div className="flex flex-row gap-2">
          <div className="self-center text-red-500 font-bold text-right">
            Supervisor: {leaveReportsDetailsData.approvalStatus.bySupervisor.comments}
            <br />
            Admin: {leaveReportsDetailsData.approvalStatus.byHr.comments}
          </div>
          <Button
            label={
              <div className="flex px-4 gap-1">
                <CircleXMarxSVG color={"white"} />
                <div>Rejected</div>
              </div>
            }
            style={"solid"}
            className={"w-[230px]"}
            backgroundColor={"#FFA5A5"}
            disabled
          />
        </div>
      );
    } else if (supervisorStatus === "approved" && hrStatus === "approved") {
      return (
        <div className="flex flex-row gap-2">
          <Button
            label={
              <div className="flex px-4 gap-1">
                <UserAddIcon color={"white"} />
                <div>Approved</div>
              </div>
            }
            style={"solid"}
            className={"w-[230px]"}
            backgroundColor={"#A5F2CE"}
            disabled
          />
        </div>
      );
    } else if (supervisorStatus === "approved" && hrStatus === "pending" && currentRole === "Supervisor") {
      return (
        <div className="flex flex-row gap-2">
          <Button
            label={
              <div className="flex px-4 gap-1">
                <UserAddIcon color={"white"} />
                <div>Approved</div>
              </div>
            }
            style={"solid"}
            className={"w-[230px]"}
            backgroundColor={"#A5F2CE"}
            disabled
          />
        </div>
      );
    } else {
      return (
        <div className="flex flex-row gap-2">
          <Button
            label={
              <div className="flex px-4 gap-1">
                <UserAddIcon color={"white"} />
                <div>
                  Pending
                  {leaveReportsDetailsData.approvalStatus.bySupervisor.status === "pending" && " (Supervisor)"}
                  {leaveReportsDetailsData.approvalStatus.bySupervisor.status === "approved" && leaveReportsDetailsData.approvalStatus.byHr.status === "pending" && " (Admin)"}
                </div>
              </div>
            }
            style={"solid"}
            className={"w-[230px]"}
            backgroundColor={"#D4D4D4"}
            disabled
          />
        </div>
      );
    }
  };

  return (
    <>
      <div className="w-full h-max relative mt-5 p-1">
        {isModalOpen && (
          <div className="w-full  bg-white/30">
            <div class="w-full fixed z-[10] inset-0 bg-black opacity-50"></div>
            <ModalDialog
              title="Reject Leave Request"
              onClose={handleCloseModal}
              onSubmit={handleModalSubmit}
              className={"max-h-[90%]"}
            >
              <h1 className="font-semibold mb-2">Add Note to your response</h1>
              <TextArea label={"Reason"} rows={5} onChange={(e) => setRejectReason(e.target.value)} />

              <div className="flex flex-row gap-2 justify-center mt-5">
                <Button
                  onMouseEnter={() => {
                    setEnter("white");
                  }}
                  onMouseLeave={() => {
                    setEnter("#DD7224");
                  }}
                  label={
                    <div className="flex px-4 gap-1">
                      <XMarkSVG color={isEnter} />
                      <div>Cancel</div>
                    </div>
                  }
                  onClick={handleCloseModal}
                  className={"w-[200px]"}
                />

                <Button
                  label={
                    <div className="flex px-4 gap-1">
                      <CircleXMarxSVG color={"white"} />
                      <div>Reject</div>
                    </div>
                  }
                  onClick={handleModalSubmit}
                  className={"w-[200px] bg-red-600"}
                  backgroundColor={"#DD4848"}
                  style={"solid"}
                />
              </div>
            </ModalDialog>
          </div>
        )}
        <FetchingAndError isFetching={isFetchingLeave} isError={errorLeave}>
          <div className="w-full  bg-white overflow-x-auto h-max">
            <div className="flex justify-between items-center">
              <div className="font-semibold">Requested at {new Date(leaveReportsDetailsEmployee.requestDate).toLocaleDateString()}</div>
              {approvalStatus()}
            </div>
          </div>
          <div className="w-full mt-5">
            <Accordion title={<div>Employee Details</div>} icons={[]} children={employeeDetail} />
          </div>
          <div className="w-full mt-5">
            <Accordion title={<div>Leave Details</div>} icons={[]} children={leaveDetail} />
          </div>
        </FetchingAndError>
      </div>
      <Outlet />
    </>
  );
};

export default LeaveDetails;
