import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppRouter from "routes";

import AlertMessage from "components/alertMessage";
import { setLoginReducer } from "store/slices/user";
import { jwtDecode } from "jwt-decode";

const App = ({ props }) => {
  const dispatch = useDispatch();
  const { currentUser, currentRole, token } = useSelector(
    (state) => state.userData,
  );

  useEffect(() => {
    // first time set reducer if got localstorage
    const localStorageCurrentUser = JSON.parse(
      localStorage.getItem("currentUser"),
    );
    const localStorageAccessToken = localStorage.getItem("accessToken");
    const localStorageCurrentRole = localStorage.getItem("currentRole");
    console.log('local storage leave current role', localStorageCurrentRole)
    if (localStorageCurrentUser && localStorageAccessToken && localStorageCurrentRole) {
      // const decodeToken = jwtDecode(localStorageAccessToken);
      // localStorage.setItem("currentUser", JSON.stringify(decodeToken));
      // localStorage.setItem("currentRole", JSON.stringify(decodeToken?.role[0]));
      // dispatch(
      //   setLoginReducer({
      //     currentUser: localStorageAccessToken
      //       ? jwtDecode(localStorageAccessToken)
      //       : localStorageCurrentUser,
      //     token: localStorageAccessToken,
      //     currentRole: localStorageCurrentRole,
      //   }),
      // );
      dispatch(setLoginReducer({
        currentUser: localStorageCurrentUser,
        token: localStorageAccessToken,
        currentRole: localStorageCurrentRole
      }));
    } else {
      if (window.__POWERED_BY_QIANKUN__) {
        window.location.href = "/";
      }
    }
  }, []);

  useEffect(() => {
    if (typeof props?.setGlobalState === "function") {
      props.setGlobalState({
        currentUser: currentUser,
        accessToken: token,
        currentRole: currentRole,
      });
    }
  }, [props, currentUser, token, currentRole]);

  return (
    <>
      <AppRouter />
      <AlertMessage />
    </>
  );
};

export default App;
