import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Header, Sidebar } from "@bluesilodev/timhutcomponents";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Outlet, useLocation, matchPath, useNavigate, NavLink } from "react-router-dom";

import { setLogoutReducer, setRoleReducer } from "store/slices/user";
import { useFetchAppsQuery } from "store/apis/commonApi";

import ChevronDownSVG from "assets/icon/ChevronDownSVG/ChevronDownSVG";
import ChevronLeftSVG from "assets/icon/ChevronLeftSVG/ChevronLeftSVG";
import UserSVG from "assets/icon/UserSVG/UserSVG";
import ProfileUser from "assets/images/profile.jpeg";
import LeaveBalanceSVG from "assets/icon/LeaveBalanceSVG/LeaveBalanceSVG";
import LeaveExpiredSVG from "assets/icon/LeaveExpiredSVG/LeaveExpiredSVG";
import LeaveSettingSVG from "assets/icon/LeaveSettingSVG/LeaveSettingSVG";
import LeaveReportSVG from "assets/icon/LeaveReportSVG/LeaveReportSVG";
import axios from "services/axios";
import { alertError } from "utils/alert";

const leaveBalanceDropDown = (location) => {
  return (
    <div>
      <div
        className="flex gap-1 rounded-md p-3 w-full items-center hover:cursor-pointer"
        onClick={() => {
          const hidden = document
            .getElementById("leave-balance")
            .classList.contains("hidden");
          if (hidden) {
            document.getElementById("leave-balance").classList.remove("hidden");
          } else {
            document.getElementById("leave-balance").classList.add("hidden");
          }
        }}
      >
        <LeaveBalanceSVG color={"black"} />
        <h1 className="text-sm font-semibold">Leave Balance</h1>
        <ChevronDownSVG />
      </div>

      <div id="leave-balance" className={`flex flex-col gap-2 mt-2`}>
        <NavLink
          to={"/leave-balance"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname === "/leave-balance" &&
            "bg-orange-500 text-white w-full"
          } ${
            matchPath(`/leave-balance-details/*`, location.pathname) &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <LeaveBalanceSVG
            color={`${
              location.pathname === "/leave-balance" || matchPath(`/leave-balance-details/*`, location.pathname) ? "white" : "black"
            } `}
            className="ms-5"
          />
          <h1 className="text-sm font-semibold">Leave Balance</h1>
        </NavLink>

        <NavLink
          to={"/leave-expired"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname === "/leave-expired" &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <LeaveExpiredSVG
            color={`${
              location.pathname === "/leave-expired" ? "white" : "black"
            }`}
            className="ms-5"
          />
          <h1 className="text-sm font-semibold">Leave Expired</h1>
        </NavLink>
      </div>
    </div>
  );
};

const Layout = () => {
  const dispatch = useDispatch();
  const { currentUser, currentRole } = useSelector((state) => state.userData);
  const [blockNonAdmin, setBlockNonAdmin] = useState(undefined);

  const navigate = useNavigate();
  const location = useLocation();

  if (currentUser?.role?.includes("SuperAdmin")) {
    window.location.href = '/employee/super/organization';
  }

  const [apps, setApps] = useState([]);
  
  const { data: resApp } = useFetchAppsQuery(null, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (resApp?.data) {
      setApps(resApp.data?.data);
    }
  }, [resApp]);

  useEffect(() => {
    if (location.pathname !== "/leave-settings") {
      // check if leave setting configured
      const localStorageSettingReady = localStorage.getItem("settingReadyLeave");
      if (localStorageSettingReady !== "true") {
        axios
          .get(`/api/leave-setting/get`)
          .then((response) => {
            if (!response.data.data) {
              if (!currentUser.role.includes("Admin")) {
                setBlockNonAdmin(true);
                return;
              }
              navigate("/leave-settings")
              alertError("Error", "Please fill leave setting first");
            } else {
              localStorage.setItem("settingReadyLeave", "true");
            }
          });
      }
    }
  }, [location.pathname])

  const onClickLogout = () => {
    dispatch(setLogoutReducer());
    localStorage.removeItem("accessToken");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentRole");

    // using window as we used microservice frontend and need to redirect to workbench
    window.location.assign("/dashboard");
  }

  const onClickSwitch = () => {
    dispatch(setRoleReducer());
  }

  const onClickApp = (link) => {
    window.location.assign(link);
  }

  const onClickMyAccount = (link) => {
    window.location.assign(`${link}/myAccount`);
  }

  /* DELETE SOON
  const changeRole = () => {
    const roleValue = role === "Employee" ? "Admin" : "Employee";
    localStorage.setItem("role", roleValue);
    navigate(0);
  };
  */

  const adminMenus = [
    {
      label: (
        <NavLink
          to={"/"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname === "/" && "bg-orange-500 text-white w-full"
          } ${
            matchPath(`/leave-details/*`, location.pathname) &&
            "bg-orange-500 text-white w-full"
          } ${
            matchPath(`/leave-excessive/*`, location.pathname) &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <LeaveReportSVG
            color={`${location.pathname === "/" || matchPath(`/leave-details/*`, location.pathname) || matchPath(`/leave-excessive/*`, location.pathname) ? "white" : "black"}`}
          />
          <h1 className="text-sm font-semibold">Leave Reports</h1>
        </NavLink>
      ),
    },

    {
      label: leaveBalanceDropDown(location),
    },

    {
      label: (
        <NavLink
          to={"/leave-type"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname === "/leave-type" &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <LeaveSettingSVG
            color={`${location.pathname === "/leave-type" ? "white" : "black"}`}
          />
          <h1 className="text-sm font-semibold">Leave Type</h1>
        </NavLink>
      ),
    },

    {
      label: (
        <NavLink
          to={"/leave-settings"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname === "/leave-settings" &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <LeaveSettingSVG
            color={`${
              location.pathname === "/leave-settings" ? "white" : "black"
            }`}
          />
          <h1 className="text-sm font-semibold">Leave Settings</h1>
        </NavLink>
      ),
    },
  ];

  const employeeMenus = [
    {
      label: (
        <NavLink
          to={"/"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname === "/" && "bg-orange-500 text-white w-full"
          }`}
        >
          <UserSVG color={`${location.pathname === "/" ? "white" : "black"}`} />
          <h1 className="text-sm font-semibold">Leave Reports</h1>
        </NavLink>
      ),
    },

    {
      label: leaveBalanceDropDown(location),
    },

    {
      label: (
        <NavLink
          to={"/leave-type"}
          className={`flex gap-1 rounded-md p-3 w-full items-center hover:scale-x-105 hover:cursor-pointer transition-all duration-300 ${
            location.pathname === "/leave-type" &&
            "bg-orange-500 text-white w-full"
          }`}
        >
          <LeaveSettingSVG
            color={`${location.pathname === "/leave-type" ? "white" : "black"}`}
          />
          <h1 className="text-sm font-semibold">Leave Type</h1>
        </NavLink>
      ),
    },
  ];

  const getTitle = () => {
    switch (location.pathname) {
      case "/":
        return "Leave Reports";

      case "/leave-balance":
        return "Leave Balance";

      case "/leave-expired":
        return "Leave Expired";

      case "/leave-type":
        return "Leave Type";

      case "/leave-settings":
        return "Leave Settings";

      default: {
        if (location.pathname.includes("/leave-details/"))
          return (
            <div className="flex flex-row items-center">
              <span onClick={() => navigate(-1)}>
                <ChevronLeftSVG className="w-[20px] h-[20px] mr-2" />
              </span>
              Leave Details
            </div>
          );
        if (location.pathname.includes("/leave-excessive/"))
          return (
            <div className="flex flex-row items-center">
              <span onClick={() => navigate(-1)}>
                <ChevronLeftSVG className="w-[20px] h-[20px] mr-2" />
              </span>
              Leave Excessive
            </div>
          );
        if (location.pathname.includes("/leave-balance-details/"))
          return (
            <div className="flex flex-row items-center">
              <span onClick={() => navigate(-1)}>
                <ChevronLeftSVG className="w-[20px] h-[20px] mr-2" />
              </span>
              Leave Balance Details
            </div>
          );
        else return "Test";
      }
    }
  };

  return (
    <div className="w-full flex ">
      <div className="w-[300px] fixed h-full overflow-y-auto z-[9]">
        <Sidebar
          menuItems={currentRole === "Admin" ? adminMenus : employeeMenus}
          className={"bg-red-100"}
          onLogoClick={() => navigate("/")}
        />
      </div>

      <div className="flex flex-col w-full ml-[300px] p-5">
        <div className="p-1">
          {
            apps.length > 0 && (
              <Header
                title={getTitle()}
                apps={apps}
                switchDisabled={currentUser?.role?.length < 2}
                userData={{
                  name: currentUser?.userName,
                  role: currentRole,
                  language: "English",
                  // switchRole: currentRole === "Admin" ? "User" : "Admin",
                  switchRole:
                    currentRole === "Admin"
                      ? (currentUser?.role.includes("Supervisor") ? "Supervisor" : "Employee")
                      : (currentUser?.role.includes("Admin") ? "Admin" : currentRole),
                  image: currentUser?.photo?.length > 0 ? currentUser?.photo[0].link : "",
                }}
                onSwitch={onClickSwitch}
                onClickMyAccount={onClickMyAccount}
                onClickLogout={onClickLogout}
                onClickApp={onClickApp}
              />
            )
          }
        </div>

        {blockNonAdmin ? (
          <React.Fragment>
            <div className="flex flex-col items-center h-full">
              <XMarkIcon width="300" />
              <div className="font-semibold">Currently unavailable. Please contact admin for configuration.</div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Outlet />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default Layout;
